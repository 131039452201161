import {
  DefaultApi,
  ListInfoRead,
  ListItemCreate,
  ListItemRead,
  ListItemUpdate,
} from "./api/api";
import { Configuration } from "./api/configuration";

// export const API_PATH = "http://localhost:8000/api";  // development
export const API_PATH = "api";

const apiConfig = new Configuration({
  basePath: API_PATH,
});
const apiService = new DefaultApi(apiConfig);

export async function getLists(): Promise<ListInfoRead[]> {
  const { data } = await apiService.readListsListsGet();
  return data;
}

export async function getListItems(listId: number): Promise<ListItemRead[]> {
  const { data } = await apiService.readListItemsListsListIdGet(listId, true);
  return data;
}

export async function addListItem(
  listId: number,
  listItem: ListItemCreate
): Promise<ListItemRead> {
  const { data } = await apiService.addListItemListsListIdPost(
    listId,
    listItem
  );
  return data;
}

export async function updateListItem(
  listId: number,
  listItemId: number,
  listItem: ListItemUpdate
): Promise<ListItemRead> {
  const { data } = await apiService.updateListItemListsListIdItemIdPatch(
    listId,
    listItemId,
    listItem
  );
  return data;
}
