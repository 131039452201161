import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MyList from "./components/MyList";
import { getLists } from "./services/apiService";

function App() {
  const [listId, setListId] = useState(1);
  const [listName, setListName] = useState("Example List");
  useEffect(() => {
    getLists().then((res) => {
      console.log(res)
      setListName(res.find(({id}) => id === listId)?.name ?? "No List");
    });
  }, [listId]);

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
        {listName}
        </Typography>
        <MyList listId={listId} listName={listName} />
      </Box>
    </Container>
  );
}
export default App;
