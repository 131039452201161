import React from "react";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { ListItemRead } from "../services/api";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

interface MyListItemProps {
  item: ListItemRead;
  checkedItems: number[];
  setCheckedItems: (id: number[]) => void;
}

export default function MyListItem({
  item,
  checkedItems,
  setCheckedItems,
}: MyListItemProps) {
  const theme = useTheme();

  const { id, label, quantity, time } = item;
  const tags = ["Tags", " Not", "Done"]; // TODO - Add tags to DB/API

  const labelId = `checkbox-list-label-${id}`;
  const handleToggle = (value: number) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  return (
    <ListItem
      key={id}
      secondaryAction={
        <IconButton edge="end" aria-label="comments">
          <EditIcon />
        </IconButton>
      }
      dense
      disablePadding
    >
      <ListItemButton role={undefined} onClick={handleToggle(id)} dense>
        <ListItemIcon>
          <Badge
            badgeContent={quantity}
            color="secondary"
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Checkbox
              edge="start"
              checked={checkedItems.indexOf(id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </Badge>
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={
            <>
              <Typography variant="body1">
                {label}
                <Typography variant="caption" sx={{ float: "right" }}>
                  {dayjs(time).fromNow(false)}
                </Typography>
              </Typography>
            </>
          }
          secondary={
            <>
              {tags &&
                id % 3 === 0 &&
                tags.map((text) => <Chip label={text} size="small" />)}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
