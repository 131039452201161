import React, { useCallback, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import AddItemTextBox from "./AddItemTextBox";
import {
  addListItem,
  getListItems,
  updateListItem,
} from "../services/apiService";
import { ListItemCreate, ListItemRead, ListItemUpdate } from "../services/api";
import { useSession } from "../services/websocketUtils";
import MyListItem from "./MyListItem";

interface MyListProps {
  listId: number;
  listName: string;
}

export interface MyListItemUpdate extends ListItemUpdate {
  id: number;
}

export interface MyListItemRemove {
  id: number;
}

enum SocketStatus {
  "CONNECTED",
  "OFFLINE",
}

export type SortTypes = "label" | "time" | "popularity";
export type SortType = {
  property: SortTypes;
  ascending: boolean;
};

function getSortIcon(property: SortTypes) {
  switch (property) {
    case "label":
      return <SortByAlphaIcon />;
    case "popularity":
      return <EmojiEmotionsIcon />;
    case "time":
    default:
      return <AccessTimeFilledIcon />;
  }
}

const sortOptions: SortType[] = [
  { property: "time", ascending: false },
  { property: "time", ascending: true },
  { property: "popularity", ascending: false },
  { property: "popularity", ascending: true },
  { property: "label", ascending: true },
  { property: "label", ascending: false },
];

export default function MyList({ listId, listName }: MyListProps) {
  const theme = useTheme();
  const [checked, setChecked] = React.useState([] as number[]);
  const [sort, setSort] = React.useState<SortType>(sortOptions[0]);
  const sortHistory = useCallback(
    (a: ListItemRead, b: ListItemRead) => {
      if (a.active && !b.active) return 1;
      if (!a.active && b.active) return -1;
      const multiplier = sort.ascending ? 1 : -1;
      let pa = a[sort.property];
      let pb = b[sort.property];
      if (sort.property === "label") {
        pa = a[sort.property].toLowerCase();
        pb = b[sort.property].toLowerCase();
      }
      if (pa < pb) return -multiplier;
      if (pa > pb) return multiplier;
      return 0;
    },
    [sort]
  );
  const [history, setHistory] = React.useState([] as ListItemRead[]);

  const list = useMemo(() => {
    return history.filter(({ active }) => active === true).sort(sortHistory);
  }, [history, sortHistory]);

  const [refreshItems, setRefreshItems] = React.useState(0);
  const [socketStatus, setSocketStatus] = React.useState(SocketStatus.OFFLINE);

  // Websocket setup
  const onOpen = useCallback(() => {
    console.log("WS Open");
    setSocketStatus(SocketStatus.CONNECTED);
  }, []);

  const onMessage = useCallback(
    (ev: MessageEvent<any>) => {
      console.log("WS Message");
      console.log(ev.data);
      try {
        const message = JSON.parse(ev.data);
        setHistory((l) => {
          const newL = l.filter(({ id }) => id !== message.data.id);
          console.log(message.data);
          newL.push(message.data);
          return newL.sort(sortHistory);
        });
        setChecked((c) => c.filter((id) => id !== message.data.id));
      } catch (err) {
        // console.log(err);
      }
    },
    [sortHistory]
  );

  const onClose = useCallback(() => {
    console.log("WS Close");
    setSocketStatus(SocketStatus.OFFLINE);
  }, []);

  const [connect, sendMessage, closeConn] = useSession(
    onOpen,
    onMessage,
    onClose
  );

  const myConnect = useCallback(() => {
    connect(listId);
    setRefreshItems((r) => r + 1);
  }, [connect, listId]);

  useEffect(() => {
    if (socketStatus !== SocketStatus.CONNECTED) {
      console.log("Connecting Websocket");
      setTimeout(myConnect, 1000, listId);
    }
  }, [socketStatus, myConnect, listId]);

  useEffect(() => {
    // console.log(`getting list ${listId}`);
    getListItems(listId).then((allItems) => {
      setHistory(allItems.sort(sortHistory));
    });
  }, [listId, refreshItems, sortHistory]);

  // User has switched back to the tab
  const onFocus = () => {
    if (socketStatus !== SocketStatus.CONNECTED) {
      myConnect();
    }
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    // console.log("Tab is blurred");
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });

  // Handle updates from AddItemTextBox
  const handleNewItem = (item: ListItemCreate) => {
    const addItem: ListItemCreate = { ...item, active: true };
    addListItem(listId, addItem)
      .then((res) => {
        // setRefreshItems(refreshItems + 1);
      })
      .catch((e) => console.warn(e));
    // sendMessage({ context: ItemContext.ITEM_CREATE, data: addItem });
  };

  const handleAddHistoryItem = (item: MyListItemUpdate) => {
    updateListItem(listId, item.id, item as ListItemUpdate)
      .then(() => {
        // setRefreshItems(refreshItems + 1);
      })
      .catch((e) => console.warn(e));
    // sendMessage({ context: ItemContext.ITEM_UPDATE, data: item });
  };

  const handleDelete = () => {
    const updatePromises: Promise<ListItemRead>[] = [];
    checked.forEach((id) => {
      //   sendMessage({
      //     context: ItemContext.ITEM_UPDATE,
      //     data: { id, active: false },
      //   });
      updatePromises.push(updateListItem(listId, id, { active: false }));
    });
    Promise.all(updatePromises)
      .then((outputs) => {
        setChecked([]);
        // setRefreshItems(refreshItems + 1);
      })
      .catch((e) => console.warn(e));
  };

  const handleSortButton = useCallback(() => {
    const currentIndex = sortOptions.indexOf(sort);
    const nextIndex = (currentIndex + 1) % sortOptions.length;
    setSort(sortOptions[nextIndex]);
  }, [sort]);

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <AddItemTextBox
          list={list}
          history={history}
          onNewItem={handleNewItem}
          onHistoryItem={handleAddHistoryItem}
        />
        <Button aria-label="sort" color="primary" onClick={handleSortButton}>
          {getSortIcon(sort.property)}
          {sort.ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </Button>

        {checked.length === 0 ? (
          <IconButton aria-label="history">
            <HistoryIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="delete" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        dense
        disablePadding
      >
        {list.map((item) => (
          <MyListItem
            item={item}
            checkedItems={checked}
            setCheckedItems={setChecked}
          />
        ))}
      </List>
      {checked.length === 0 ? ( // TODO do something with this?
        <></>
      ) : (
        <Fab
          size="medium"
          aria-label="delete-items"
          onClick={handleDelete}
          sx={{
            background: theme.palette.error.main,
            color: theme.palette.background.default,
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <DeleteIcon />
        </Fab>
      )}
    </Box>
  );
}
