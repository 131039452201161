/* tslint:disable */
/* eslint-disable */
/**
 * Lists API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ListInfoCreate
 */
export interface ListInfoCreate {
    /**
     * 
     * @type {string}
     * @memberof ListInfoCreate
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ListInfoRead
 */
export interface ListInfoRead {
    /**
     * 
     * @type {string}
     * @memberof ListInfoRead
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ListInfoRead
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ListItemCreate
 */
export interface ListItemCreate {
    /**
     * 
     * @type {boolean}
     * @memberof ListItemCreate
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListItemCreate
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof ListItemCreate
     */
    time?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemCreate
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemCreate
     */
    popularity?: number;
}
/**
 * 
 * @export
 * @interface ListItemRead
 */
export interface ListItemRead {
    /**
     * 
     * @type {number}
     * @memberof ListItemRead
     */
    list_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListItemRead
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListItemRead
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof ListItemRead
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemRead
     */
    popularity: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemRead
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemRead
     */
    time: number;
}
/**
 * 
 * @export
 * @interface ListItemUpdate
 */
export interface ListItemUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof ListItemUpdate
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListItemUpdate
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof ListItemUpdate
     */
    time?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemUpdate
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ListItemUpdate
     */
    popularity?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add List Item
         * @param {number} listId 
         * @param {ListItemCreate} listItemCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addListItemListsListIdPost: async (listId: number, listItemCreate: ListItemCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('addListItemListsListIdPost', 'listId', listId)
            // verify required parameter 'listItemCreate' is not null or undefined
            assertParamExists('addListItemListsListIdPost', 'listItemCreate', listItemCreate)
            const localVarPath = `/lists/{list_id}/`
                .replace(`{${"list_id"}}`, encodeURIComponent(String(listId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listItemCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create List
         * @param {ListInfoCreate} listInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListListsPost: async (listInfoCreate: ListInfoCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listInfoCreate' is not null or undefined
            assertParamExists('createListListsPost', 'listInfoCreate', listInfoCreate)
            const localVarPath = `/lists/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listInfoCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List Items
         * @param {number} listId 
         * @param {boolean} [includeHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListItemsListsListIdGet: async (listId: number, includeHistory?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('readListItemsListsListIdGet', 'listId', listId)
            const localVarPath = `/lists/{list_id}`
                .replace(`{${"list_id"}}`, encodeURIComponent(String(listId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeHistory !== undefined) {
                localVarQueryParameter['include_history'] = includeHistory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListsListsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update List Item
         * @param {number} listId 
         * @param {number} itemId 
         * @param {ListItemUpdate} listItemUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListItemListsListIdItemIdPatch: async (listId: number, itemId: number, listItemUpdate: ListItemUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('updateListItemListsListIdItemIdPatch', 'listId', listId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('updateListItemListsListIdItemIdPatch', 'itemId', itemId)
            // verify required parameter 'listItemUpdate' is not null or undefined
            assertParamExists('updateListItemListsListIdItemIdPatch', 'listItemUpdate', listItemUpdate)
            const localVarPath = `/lists/{list_id}/{item_id}`
                .replace(`{${"list_id"}}`, encodeURIComponent(String(listId)))
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listItemUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add List Item
         * @param {number} listId 
         * @param {ListItemCreate} listItemCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addListItemListsListIdPost(listId: number, listItemCreate: ListItemCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListItemRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addListItemListsListIdPost(listId, listItemCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create List
         * @param {ListInfoCreate} listInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createListListsPost(listInfoCreate: ListInfoCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInfoRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createListListsPost(listInfoCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List Items
         * @param {number} listId 
         * @param {boolean} [includeHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListItemsListsListIdGet(listId: number, includeHistory?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListItemRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListItemsListsListIdGet(listId, includeHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListsListsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListInfoRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListsListsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRootGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update List Item
         * @param {number} listId 
         * @param {number} itemId 
         * @param {ListItemUpdate} listItemUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateListItemListsListIdItemIdPatch(listId: number, itemId: number, listItemUpdate: ListItemUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListItemRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateListItemListsListIdItemIdPatch(listId, itemId, listItemUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Add List Item
         * @param {number} listId 
         * @param {ListItemCreate} listItemCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addListItemListsListIdPost(listId: number, listItemCreate: ListItemCreate, options?: any): AxiosPromise<ListItemRead> {
            return localVarFp.addListItemListsListIdPost(listId, listItemCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create List
         * @param {ListInfoCreate} listInfoCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createListListsPost(listInfoCreate: ListInfoCreate, options?: any): AxiosPromise<ListInfoRead> {
            return localVarFp.createListListsPost(listInfoCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List Items
         * @param {number} listId 
         * @param {boolean} [includeHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListItemsListsListIdGet(listId: number, includeHistory?: boolean, options?: any): AxiosPromise<Array<ListItemRead>> {
            return localVarFp.readListItemsListsListIdGet(listId, includeHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListsListsGet(options?: any): AxiosPromise<Array<ListInfoRead>> {
            return localVarFp.readListsListsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRootGet(options?: any): AxiosPromise<any> {
            return localVarFp.readRootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update List Item
         * @param {number} listId 
         * @param {number} itemId 
         * @param {ListItemUpdate} listItemUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateListItemListsListIdItemIdPatch(listId: number, itemId: number, listItemUpdate: ListItemUpdate, options?: any): AxiosPromise<ListItemRead> {
            return localVarFp.updateListItemListsListIdItemIdPatch(listId, itemId, listItemUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Add List Item
     * @param {number} listId 
     * @param {ListItemCreate} listItemCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addListItemListsListIdPost(listId: number, listItemCreate: ListItemCreate, options?: any) {
        return DefaultApiFp(this.configuration).addListItemListsListIdPost(listId, listItemCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create List
     * @param {ListInfoCreate} listInfoCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createListListsPost(listInfoCreate: ListInfoCreate, options?: any) {
        return DefaultApiFp(this.configuration).createListListsPost(listInfoCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List Items
     * @param {number} listId 
     * @param {boolean} [includeHistory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readListItemsListsListIdGet(listId: number, includeHistory?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).readListItemsListsListIdGet(listId, includeHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readListsListsGet(options?: any) {
        return DefaultApiFp(this.configuration).readListsListsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readRootGet(options?: any) {
        return DefaultApiFp(this.configuration).readRootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update List Item
     * @param {number} listId 
     * @param {number} itemId 
     * @param {ListItemUpdate} listItemUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateListItemListsListIdItemIdPatch(listId: number, itemId: number, listItemUpdate: ListItemUpdate, options?: any) {
        return DefaultApiFp(this.configuration).updateListItemListsListIdItemIdPatch(listId, itemId, listItemUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


