import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ListItemCreate } from "../services/api";
import { MyListItemUpdate } from "./MyList";

interface InputListItemType {
  id?: number;
  label: string;
  inputValue?: string;
  active?: boolean;
  time?: number;
  quantity?: number;
  popularity?: number;
  tags?: string[];
}

const filter = createFilterOptions<InputListItemType>({
  stringify: (option) => option.label,
});

interface AddItemBoxTextProps {
  list: InputListItemType[];
  history: InputListItemType[];
  onNewItem: (item: ListItemCreate) => void;
  onHistoryItem: (item: MyListItemUpdate) => void;
}

export default function AddItemTextBox({
  list,
  history,
  onNewItem,
  onHistoryItem,
}: AddItemBoxTextProps) {
  const [value, setValue] = React.useState<InputListItemType | null>(null);
  const [open, toggleOpen] = React.useState(false);

  // TODO - remove list as it isn't needed? (or keep but change output?)
  const [dialogValue, setDialogValue] = React.useState({
    label: "",
    quantity: 1,
  });

  const handleClose = () => {
    setDialogValue({
      label: "",
      quantity: 1,
    });
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newItem: ListItemCreate = {
      label: dialogValue.label,
      active: true,
    };
    if (dialogValue.quantity > 1) {
      newItem.quantity = dialogValue.quantity;
    }
    onNewItem(newItem);
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        size="small"
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                label: newValue,
                quantity: 1,
              });
            });
          } else if (newValue && newValue.inputValue) {
            // Item not in history
            toggleOpen(true);
            setDialogValue({
              label: newValue.inputValue,
              quantity: 1,
            });
          } else {
            // An existing item from history
            if (newValue) {
              onHistoryItem({
                id: newValue.id,
                active: true,
                quantity: 0,
              } as MyListItemUpdate);
            }
            setValue({ label: "", id: -1, time: 0 });
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: `Create new item "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="add-list-item-selection-box"
        options={history}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props} key={option.id} >{option.label}</li>}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Add item" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add an item that has not been added before.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.label}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  label: event.target.value,
                })
              }
              label="label"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.quantity}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  quantity: parseInt(event.target.value),
                })
              }
              label="quantity"
              type="number"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
