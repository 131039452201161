import { useCallback, useEffect, useState } from "react";
import { API_PATH } from "./apiService";
import {
  SessionConnectHandler,
  SessionDisconnectHandler,
  SessionHook,
  SessionMessageHandler,
  WSItemMessage,
} from "./types";

export function useSession(
  onOpen: SessionConnectHandler,
  onMessage: SessionMessageHandler,
  onClose: SessionDisconnectHandler
): SessionHook {
  const [session, setSession] = useState(null as unknown as WebSocket);

  const updateOpenHandler = () => {
    if (!session) return;
    session.addEventListener("open", onOpen);
    return () => {
      session.removeEventListener("open", onOpen);
    };
  };

  const updateMessageHandler = () => {
    if (!session) return;
    session.addEventListener("message", onMessage);
    return () => {
      session.removeEventListener("message", onMessage);
    };
  };

  const updateCloseHandler = () => {
    if (!session) return;
    session.addEventListener("close", onClose);
    return () => {
      session.removeEventListener("close", onClose);
    };
  };

  useEffect(updateOpenHandler, [session, onOpen]);
  useEffect(updateMessageHandler, [session, onMessage]);
  useEffect(updateCloseHandler, [session, onClose]);

  const connect = useCallback((listId: number) => {
    const base = window.location.href.replace("http", "ws");
    const uri = `${base}${API_PATH}/lists/${listId}/ws`;
    console.log(uri);
    const ws = new WebSocket(uri);
    setSession(ws);
  }, []);

  const sendMessage = <T extends WSItemMessage>(args: T) => {
    session.send(JSON.stringify(args));
  };

  const close = useCallback(() => {
    if (session.readyState === session.OPEN) session.close(1001);
  }, [session]);

  return [connect, sendMessage, close];
}
